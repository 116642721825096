import React, { useRef, useEffect, useState } from "react";


import "./Home.css"

import ClaimText from "../img/claim.svg"
import Logo from "../img/logo.png"
import HomeUpLeftHUD from "../img/homeUpLeftHUD.svg"
import FooterLogo from "../img/footerLogo.svg"
import BottomLeftHUD from "../img/bottomLeftHUD.svg"
import RightHud from "../img/rightHUD.svg"

function Home() {
    const [countdown, setCountdown] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC');
                const data = await response.json();
                const currTime = new Date(data.datetime)
                const targetTime = new Date('2023-07-05T21:00:00+02:00');
                const timeDifference = targetTime.getTime() - currTime.getTime();
                console.log(timeDifference)

                if (timeDifference > 0) {
                    setCountdown(timeDifference);
                } else {
                    setCountdown(0);
                }
            } catch (error) {
                console.log('Error:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (countdown !== null && countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1000);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const formatTime = (milliseconds) => {
        let diff = new Date(milliseconds)

        return diff.toISOString().substring(14, 19);
    };

  return (
    <>
      <div className="Home" >
        <img className="logo" src={Logo} style={{}} alt="logo" />
        <img className="upleftHUD" src={HomeUpLeftHUD} alt="HUD" />
        <img className="claim" src={ClaimText} alt="Claim" />
        <div className="buttons">
          <h2>Rhascau is under maintenance</h2>
      {/**<h4>Follow our official Discord and Twitter for information about Rhascau maintenance status.</h4>**/}
        </div>
        <div className="bottomleft"><img className="bottomleftHUD" src={BottomLeftHUD} alt="HUD" /></div>
        <img className="rightHUD" src={RightHud} alt="HUD" />
        <div className="rightshome"><img className="bottomrightHUD" src={FooterLogo} alt="HUD" /></div>
      </div>
    </>
  );
}

export default Home;
