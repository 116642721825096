import DiscordIcon from "../img/discord.svg"
import TwitterIcon from "../img/twitter.svg"
import GithubIcon from "../img/GithubLogoNoBG.svg"

import "./Footer.css"

export default function Footer() {

    return (
        <div className="footer">
            <div className="terms footerDivWidth">
            </div>
            <div className="socials footerDivWidth">
                <a className="discord" href="http://discord.gg/mintersworld" target="blank" >
                    <img className="socialButton" src={DiscordIcon} alt="Discord" />
                </a>
                <a className="twitter" href="https://twitter.com/rhascau" target="blank" >
                    <img className="socialButton" src={TwitterIcon} alt="Twitter" />
                </a>
                <a className="github" href="https://github.com/Digital-Avatars-and-Robotics" target="blank" >
                    <img className="socialButton" src={GithubIcon} alt="Github" />
                </a>
            </div>
            <div className="rights footerDivWidth">
                <div className="rightstext">® 2023 Rhascau (v0.9). All right reserved.</div>
            </div>
        </div>
    )
}
