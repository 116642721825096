import React, { useEffect, useState } from 'react';

import './App.css';
import Home from "./components/Home"
import Footer from "./components/Footer"

import BackgroundVideo from "./img/main_background.webm";

function App() {
    return (
        <>
                <video className="video" autoPlay muted loop id="bgvideo" >
                    <source src={BackgroundVideo} type="video/webm" />
                </video>
        <Home />
        <Footer />
        </ >
      );
}

export default App;
