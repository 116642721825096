import DiscordIcon from "../img/discord.svg"
import TwitterIcon from "../img/twitter.svg"
import GithubIcon from "../img/GithubLogoNoBG.svg"

import "./Footer.css"

export default function Footer() {

    return (
        <div className="footer">
            <div className="terms footerDivWidth">
            </div>
            <div className="socials footerDivWidth">
            </div>
            <div className="rights footerDivWidth">
                <div className="rightstext">® 2023 Rhascau (v0.9). All right reserved.</div>
            </div>
        </div>
    )
}
